import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import COLORS from '../utils/colors';
import { ImBlog } from 'react-icons/im';
import { GoHome } from 'react-icons/go';
import { GiSheep } from 'react-icons/gi';
import { AiOutlineMail } from 'react-icons/ai';

export const Header = ({ siteTitle }) => (
  <Navbar>
    <Navlogo>
      <GiSheep style={{ marginRight: '0.5em' }} />
      <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/'>{siteTitle}</Link>
    </Navlogo>
    <NavlinkGroup>
      <Navlink activeClassName='active' to='/'>
        <GoHome style={{ marginRight: '0.5em' }} />
        Home
      </Navlink>
      <Navlink activeClassName='active' to='/blog'>
        <ImBlog style={{ marginRight: '0.5em' }} />
        Blog
      </Navlink>
      <Navlink activeClassName='active' to='/contact'>
        <AiOutlineMail style={{ marginRight: '0.5em' }} />
        Contact
      </Navlink>
    </NavlinkGroup>
  </Navbar>
);

// Component elements & styles
const Navbar = styled.div`
  background-color: ${COLORS.blue};
  overflow: hidden;
  color: ${COLORS.white};
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
`;

const Navlogo = styled.div`
  color: ${COLORS.white};
  text-decoration: none;
  font-size: 1.5em;
  display: flex;
  align-items: center;
`;

const NavlinkGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const Navlink = styled(Link)`
  color: ${COLORS.white};
  padding: 1.1em 0.5em;
  text-decoration: none;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${COLORS.white};
    color: ${COLORS.black};
  }

  &.active {
    background-color: ${COLORS.lavender};
    color: white;
  }
`;
