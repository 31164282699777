import React from 'react';
import styled from 'styled-components';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { Header } from './Header';
import { Footer } from './Footer';

const AppStyles = styled.main`
  width: 800px;
  margin: 0 auto;
  font-family: 'Lora';
  font-size: 16px;
  position: relative;
  min-height: 100vh;
  line-height: 1.7em;

  /* I am not going to support mobile */
  @media (max-width: 888px) {
    width: 600px;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6 { font-family: 'Arvo'; }
`;

export const Layout = ({ children }) => {
  const { title, copyright } = useSiteMetadata();
  return (
    <AppStyles>
      <Header siteTitle={title} />
      {children}
      <Footer copyright={copyright} />
    </AppStyles>
  );
};
