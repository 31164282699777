import COLORS from '../utils/colors';
import React from 'react';

export const FadedText = ({ style, children }) => (
  <span
    style={{
      color: COLORS.gray,
      fontWeight: 200,
      ...style,
    }}
  >
    {children}
  </span>
);
