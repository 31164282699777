import React from 'react';
import styled from 'styled-components';
import COLORS from '../utils/colors';
import { FadedText } from '../elements/FadedText';

export const Footer = ({ copyright }) => {
  return (
    <>
      <FooterWrapper>
        <hr />
        <FooterContent>
          <FooterAuthor>Author: Jim Griffith</FooterAuthor>
          <FadedText>&#169; {copyright}</FadedText>
          <FooterEmail href='mailto:jim@sheepsort.com'>
            jim@sheepsort.com
          </FooterEmail>
        </FooterContent>
      </FooterWrapper>
    </>
  );
};

// Styles

// The footer goes on the floor
const FooterWrapper = styled.footer`
  padding: 1em 0;
  position: absolute;
  width: 100%;
  height: 3rem;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FooterAuthor = styled.div`
  font-size: 1em;
`;

const FooterEmail = styled.a`
  text-decoration: none;
  color: ${COLORS.blue};
`;
